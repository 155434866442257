import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { AUTH_TOKEN } from '@/constants';
import authRoutes from '@/modules/auth/router';
import adminRoutes from '@/modules/admin/router';
import NotAuthorized from '@/views/NotAuthorized.vue';
import Tracking from '@/views/TrackingView.vue';
import { store } from '@/store'; // Ensure your store is properly imported
import i18n from '@/i18n';
// import getLoggedUser from '@/api/auth/getLoggedUser';
// import { LOGIN_USER } from '@/store/auth/constants';
// import { decryptToken } from '@/composables/encryptionUtil';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:lang',
    name: 'locale',
    redirect: { name: 'login' },
    children: [
      {
        path: 'not-authorized',
        name: 'not-authorized',
        component: NotAuthorized,
      },
      {
        path: 'tracking',
        name: 'tracking.global',
        component: Tracking,
      },
      ...authRoutes,
      ...adminRoutes.map((route) => ({
        ...route,
        // meta: { requiresAuth: true },
      })),
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  // If no language param is set, redirect to the default language route
  if (!to.params.lang) {
    const defaultLanguage = process.env.VUE_APP_DEFAULT_LOCALE || 'pt';
    return next({ name: 'locale', params: { lang: defaultLanguage } });
  }

  // Set the language in i18n to current param lang
  i18n.global.locale.value = to.params.lang as string;

  // Update the URL tab title
  document.title =
    typeof to.meta?.title === 'string' ? to.meta.title : 'PORTAL DO CLIENTE';

  // Check if the route requires authentication
  const token = window.localStorage.getItem(AUTH_TOKEN);

  if (to.matched.some((route) => route.meta.requiresAuth)) {
    // alert('auth place');
    if (!store.getters.isLoggedIn) {
      router.push({ name: 'login', params: { lang: to.params.lang } });
    }

    if (token) {
      try {
        return next();
      } catch (error) {
        const noInternet = window.navigator.onLine ? false : true;
        if (noInternet) {
          alert('No Internet Connection');
          return next(false);
        } else {
          return next({ name: 'login', params: { lang: to.params.lang } });
        }
      }
    }

    return next({ name: 'login', params: { lang: to.params.lang } });
  } else {
    next();
  }
});

export default router;
