// Styles
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';

// Vuetify
import { createVuetify, ThemeDefinition } from 'vuetify';
import * as directives from 'vuetify/directives';

const myCustomLightTheme: ThemeDefinition = {
  dark: false,
  colors: {
    surface: '#ffffff',
    muted: '#E3DDE3',
    'primary-dark': '#1d051a',
    primary: '#870b49',
    'primary-lighten': '#944c8b',
    'primary-lighten-1': '#524750',
    'primary-lighten-2': '#938391',
    secondary: '#e5d5e1',
    'secondary-lighten-1': '#C0D4A6',
    tertiary: '#c9d30',
    danger: '#982C2C',
    info: '#32889F',
    success: '#368536',
    warning: '#C7C250',
  },
};

export default createVuetify({
  components: {},
  theme: {
    defaultTheme: 'myCustomLightTheme',
    themes: {
      myCustomLightTheme,
    },
  },
  directives,
});
